<div class="rodape">
  <div class="container">
    <div class="row" style="max-width: 1024px;">
      <div class="col">
        <div class="d-flex justify-content-center">
          <h1 class="titulo">
            <i class="fas fa-calculator"></i> SMJ - CALCULADORA JURÍDICA (

            <a href="https://joshuamr.com.br">joshuaMR</a>
            )
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>
