<form #painelForm="ngForm">
  <div id="html" class="painel">
    <div class="container" style="margin-top: 15px">
      <div class="card card-principal">
        <div class="card-header card-header-icon card-header-info">
          <div class="card-icon">
            <i class="material-icons">search</i>
          </div>
          <h2 class="card-title">
            Encargos Incidentes Sobre Tarifas Declaradas Ilegais
          </h2>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="qtdParcelas">Quantidade de Parcelas</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-chart-bar"></i>
                    </span>
                  </div>
                  <input
                    type="number"
                    [(ngModel)]="qtdParcelas"
                    name="qtdParcelas"
                    class="form-control"
                    id="qtdParcelas"
                    placeholder="Quantidade de Parcelas"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="valorParcelas">Valor de Cada Parcela</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons prefix">monetization_on</i>
                    </span>
                  </div>
                  <input
                    type="number"
                    [(ngModel)]="valorParcelas"
                    name="valorParcelas"
                    class="form-control"
                    id="valorParcelas"
                    placeholder="Valor Parcelas"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="montanteValorFinanciado"
                  >Montante Total de Financiamento</label
                >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-money-check-alt"></i>
                    </span>
                  </div>
                  <input
                    type="number"
                    [(ngModel)]="montanteValorFinanciado"
                    name="montanteValorFinanciado"
                    class="form-control"
                    id="montanteValorFinanciado"
                    placeholder="Montante Financiado"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="valorTarifasIlegais"
                  >Valor de Tarifas Ilegais</label
                >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-ticket-alt"></i>
                    </span>
                  </div>
                  <input
                    type="number"
                    [(ngModel)]="valorTarifasIlegais"
                    name="valorTarifasIlegais"
                    class="form-control"
                    id="valorTarifasIlegais"
                    placeholder="Valor de Tarifas Ilegais"
                  />
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 10px" class="row">
            <div class="col-sm-12">
              <div class="div-botoes">
                <button
                  type="submit"
                  class="btn botaoimprimir"
                  (click)="print()"
                >
                  Imprimir <i class="fas fa-print"></i>
                </button>
                <button type="submit" class="btn botoes" (click)="calcular()">
                  Calcular <i class="material-icons right">play_arrow</i>
                </button>
              </div>
            </div>
          </div>
          <br />
          <hr style="background-color: #000080" />
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="card cards card-stats card-valor-total">
                <div class="card-header card-header-warning card-header-icon">
                  <div class="card-icon">
                    <i class="fas fa-money-check-alt"></i>
                  </div>
                  <p style="color: #fb8c00" class="card-category">
                    Valor Total Pago
                  </p>
                  <h3 style="color: #fb8c00" class="card-title">
                    {{ valorTotalFinanciado | currency: "BRL":true }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="card cards card-stats card-valor-total-encargos">
                <div class="card-header card-header-rose card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">monetization_on</i>
                  </div>
                  <p style="color: #d81b60" class="card-category">
                    Valor total dos encargos incidentes no financiamento
                  </p>
                </div>
                <div class="card-body">
                  <h3 style="color: #d81b60" class="card-title">
                    {{ valorEncargos | currency: "BRL":true }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="card cards card-stats card-percentual-encargos">
                <div class="card-header card-header-success card-header-icon">
                  <div class="card-icon">
                    <i class="fas fa-percentage"></i>
                  </div>
                  <p style="color: #43a047" class="card-category">
                    Percentual dos encargos totais incidentes no contrato
                  </p>
                  <h3 style="color: #43a047" class="card-title">
                    {{
                      percentoEncargosIncidentesFinanciamento | number: "1.2-2"
                    }}
                    %
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="card cards card-stats card-valor-encargos">
                <div class="card-header card-header-info card-header-icon">
                  <div class="card-icon">
                    <i class="fas fa-cash-register"></i>
                  </div>
                  <p style="color: #000080" class="card-category">
                    Valor dos encargos em cada parcela
                  </p>
                  <h1 style="color: #000080" class="card-title">
                    {{ valorEncargosCadaParcela | currency: "BRL":true }}
                  </h1>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="card cards card-stats card-encargos-incidentes">
                <div class="card-header card-header-primary card-header-icon">
                  <div class="card-icon">
                    <i class="fas fa-cash-register"></i>
                  </div>
                  <p style="color: #9c27b0" class="card-category">
                    Encargos Incidentes Sobre Tarifas Declaradas Ilegais
                  </p>
                  <h1 style="color: #9c27b0" class="card-title">
                    {{
                      encargosIncidentesSobreTarifasIlegais
                        | currency: "BRL":true
                    }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
