<div class="topo">
  <div class="container">
    <div class="row" style="max-width: 1024px">
      <div class="col">
        <div class="d-flex justify-content-center">
          <h1 class="titulo">
            <i class="fas fa-calculator"></i> SMJ - CALCULADORA JURÍDICA (

            <a href="https://joshuamr.com.br/">joshuaMR</a>
            )
          </h1>
        </div>
      </div>
    </div>
    <div class="dropdown show barra">
      <a
        class="btn dropdown-toggle menu"
        href="javascript:;"
        role="button"
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Menu
      </a>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <a
          [routerLink]="['/home']"
          routerLinkActive="router-link-active"
          class="dropdown-item"
          href="javascript:;"
          >Encargos Incidentes</a
        >
        <a
          [routerLink]="['/dpvat']"
          routerLinkActive="router-link-active"
          class="dropdown-item"
          href="javascript:;"
          >DPVAT</a
        >
      </div>
    </div>
  </div>
</div>
