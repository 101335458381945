import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';

@Component({
  selector: 'app-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.css'],
})
export class PainelComponent implements OnInit {
  public qtdParcelas: number;
  public valorParcelas: number;
  public montanteValorFinanciado: number;
  public valorTarifasIlegais: number;
  public valorTotalFinanciado: number = 0;
  public valorEncargos: number = 0;
  public percentoEncargosIncidentesFinanciamento: number = 0;
  public encargosIncidentesSobreTarifasIlegais: number = 0;
  public valorEncargosCadaParcela: number = 0;

  constructor() {}

  calcular() {
    if (
      this.qtdParcelas &&
      this.valorParcelas &&
      this.montanteValorFinanciado &&
      this.valorTarifasIlegais
    ) {
      this.valorTotalFinanciado = this.qtdParcelas * this.valorParcelas;
      this.valorEncargos =
        this.valorTotalFinanciado - this.montanteValorFinanciado;
      this.percentoEncargosIncidentesFinanciamento =
        (this.valorEncargos / this.montanteValorFinanciado) * 100;
      this.encargosIncidentesSobreTarifasIlegais =
        (this.percentoEncargosIncidentesFinanciamento *
          this.valorTarifasIlegais) /
        100;
      this.valorEncargosCadaParcela =
        this.encargosIncidentesSobreTarifasIlegais / this.qtdParcelas;
    } else {
      swal.fire('Erro', 'Preencha todos os valores!!', 'error');
    }
  }

  print() {
    window.print();
  }

  ngOnInit(): void {}
}
