<form #painelForm="ngForm">
  <div id="html" class="painel">
    <div class="container" style="margin-top: 15px">
      <div
        class="card card-principal"
        style="margin-bottom: 10px; border: 1px solid #000080"
      >
        <div class="card-header card-header-icon card-header-info">
          <div class="card-icon">
            <i class="material-icons">search</i>
          </div>
          <h2 class="card-title">Calculadora DPVAT</h2>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="dano">Danos Corporais</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-ambulance"></i>
                    </span>
                  </div>
                  <ng-select
                    id="dano"
                    [items]="danosCorporais"
                    [(ngModel)]="dano"
                    bindLabel="dano"
                    class="NgSelect form-control"
                    [ngModelOptions]="{ standalone: true }"
                    [searchable]="true"
                    required="true"
                    [searchFn]="customSearchFn"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <span>{{ item.dano }} - {{ item.porcento }} %</span>
                    </ng-template>
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-search="searchTerm"
                      let-index="index"
                    >
                      <span>{{ item.dano }} - {{ item.porcento }} %</span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="indenizacao">Idenização</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-comment-dollar"></i>
                    </span>
                  </div>
                  <ng-select
                    id="dano"
                    [items]="indenizacoes"
                    [(ngModel)]="indenizacao"
                    bindLabel="indenizacao"
                    class="NgSelect form-control"
                    [ngModelOptions]="{ standalone: true }"
                    [searchable]="true"
                    required="true"
                    [searchFn]="customSearchFnI"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <span>{{ item.descricao }} - {{ item.porcento }} %</span>
                    </ng-template>
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-search="searchTerm"
                      let-index="index"
                    >
                      <span>{{ item.descricao }} - {{ item.porcento }} %</span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 10px" class="row">
            <div class="col-sm-12">
              <div class="div-botoes">
                <button
                  type="submit"
                  class="btn botaoimprimir"
                  (click)="print()"
                >
                  Imprimir <i class="fas fa-print"></i>
                </button>
                <button type="submit" class="btn botoes" (click)="calcular()">
                  Calcular <i class="material-icons right">play_arrow</i>
                </button>
              </div>
            </div>
          </div>
          <br />
          <hr style="background-color: #000080" />
          <div class="row">
            <div class="col-sm-12">
              <div class="card text-center card-valor-encargos">
                <div class="card-header card-header-info text-center">
                  <div class="card-icon">
                    <i class="material-icons">monetization_on</i>
                  </div>
                  <h2 class="card-title">Valor da Idenização</h2>
                </div>
                <div class="card-body">
                  <h1 style="color: #000080" class="card-title">
                    {{ valorCalculado | currency: "BRL":true }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
