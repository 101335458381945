import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { DanoCorporal } from './dano-corporal.model';
import { Indenizacao } from './indenizacao.model';

@Component({
  selector: 'app-calcdpvat',
  templateUrl: './calcdpvat.component.html',
  styleUrls: ['./calcdpvat.component.css'],
})
export class CalcdpvatComponent implements OnInit {
  public danosCorporais: DanoCorporal[] = [
    {
      dano:
        'Perda anatômica e/ou funcional completa de ambos os membros superiores ou inferiores',
      porcento: 100,
    },
    {
      dano:
        'Perda anatômica e/ou funcional completa de ambas as mãos ou de ambos os pés',
      porcento: 100,
    },
    {
      dano:
        'Perda anatômica e/ou funcional completa de um membro superior e de um membro inferior',
      porcento: 100,
    },
    {
      dano:
        'Perda completa da visão em ambos os olhos (cegueira bilateral) ou cegueira legal bilateral',
      porcento: 100,
    },
    {
      dano:
        'Lesões neurológicas que cursem com: (a) dano cognitivo-comportamental',
      porcento: 100,
    },
    {
      dano:
        'Lesões neurológicas que cursem com: (b) impedimento do senso de orientação espacial e/ou do livre deslocamento corporal',
      porcento: 100,
    },
    {
      dano:
        'Lesões neurológicas que cursem com: (c) perda completa do controle esfincteriano',
      porcento: 100,
    },
    {
      dano:
        'Lesões neurológicas que cursem com: (d) comprometimento de função vital ou autonômica',
      porcento: 100,
    },
    {
      dano:
        'Lesões de órgãos e estruturas crânio-faciais, cervicais, torácicos, abdominais, pélvicos ou retro-peritoneais cursando com prejuízos funcionais não compensáveis de ordem autonômica, respiratória, cardiovascular, digestiva, excretora ou de qualquer outra espécie, desde que haja comprometimento de função vital',
      porcento: 100,
    },
    {
      dano:
        'Perda anatômica e/ou funcional completa de um dos membros superiores e/ou de uma das mãos',
      porcento: 70,
    },
    {
      dano:
        'Perda anatômica e/ou funcional completa de um dos membros inferiores',
      porcento: 70,
    },
    {
      dano: 'Perda anatômica e/ou funcional completa de um dos pés',
      porcento: 50,
    },
    {
      dano:
        'Perda completa da mobilidade de um dos ombros, cotovelos, punhos ou dedo polegar',
      porcento: 25,
    },
    {
      dano: 'Perda completa da mobilidade de um quadril, joelho ou tornozelo',
      porcento: 25,
    },
    {
      dano:
        'Perda anatômica e/ou funcional completa de qualquer um dentre os outros dedos da mão',
      porcento: 10,
    },
    {
      dano:
        'Perda anatômica e/ou funcional completa de qualquer um dos dedos do pé',
      porcento: 10,
    },
    {
      dano:
        'Perda auditiva total bilateral (surdez completa) ou da fonação (mudez completa) ou da visão de um olho',
      porcento: 50,
    },
    {
      dano:
        'Perda completa da mobilidade de um segmento da coluna vertebral exceto o sacral',
      porcento: 25,
    },
    {
      dano: 'Perda integral (retirada cirúrgica) do baço',
      porcento: 10,
    },
  ];
  public indenizacoes: Indenizacao[] = [
    { descricao: 'Perdas completa', porcento: 100 },
    { descricao: 'Perdas de repercussão intensa', porcento: 75 },
    { descricao: 'Perdas de média repercussão', porcento: 50 },
    { descricao: 'Perdas de leve repercussão', porcento: 25 },
    { descricao: 'Perdas de sequelas residuais', porcento: 10 },
  ];
  public dano: DanoCorporal = {
    dano:
      'Perda anatômica e/ou funcional completa de ambos os membros superiores ou inferiores',
    porcento: 100,
  };
  public indenizacao: Indenizacao = {
    descricao: 'Perdas completa',
    porcento: 100,
  };
  public valorMaximo: number = 13500;
  public valorCalculado: number = 0;

  constructor() {}

  calcular() {
    if (this.dano && this.indenizacao) {
      this.valorCalculado =
        this.valorMaximo *
        (this.dano.porcento / 100) *
        (this.indenizacao.porcento / 100);
    } else {
      swal.fire('Erro', 'Preencha todos os valores!!', 'error');
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.dano.toLocaleLowerCase().indexOf(term) > -1;
  }

  customSearchFnI(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.descricao.toLocaleLowerCase().indexOf(term) > -1;
  }

  print() {
    window.print();
  }

  ngOnInit(): void {}
}
